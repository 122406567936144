.industry-oi-cat{
    padding: 1%;
}

.industry h3 {
    color: #33657E;
    font-size: 32px;
    text-align: center;
}

.industry-oi-cat-item {
    
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    color:#33657E;
    border-radius: 10px;
    border: 1px solid #F5F5F5;
    background: #FFF;
    box-shadow: 0px 14px 24px -10px #C7D6DE;
    padding: 6%;
    margin: 12% 0;
    height: 250px;
    
    
}

.industry-oi-cat-item img{
    width: 100%;
    height: 150px;
    margin: 0 10%;
}